<template>
  <v-select
    :label='$t("model.name.classroom")'
    class="white"
    :items="data"
    item-text="name"
    item-value="id"
    v-model="id"
    :rules="rules"
    :loading="api.isLoading"
  ></v-select>
</template>

<script>
import i18n from '@/plugins/i18n'
export default {
  props: ["callbackSelect", "callbackError","schoolId"],
  data: () => ({
     rules : [v => !!v || i18n.t("rules.classroom")],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
  watch: {
    //BOC:[parent]
    id: function (val) {
      this.id = val;
      this.callbackSelect(val);
    },
    //EOC
  },
  methods: {
    fetch() {
       this.api.url =
      this.$api.servers.backend +  "/v1/"+this.$_getLocale()+"/data/classroom?schoolId="+this.schoolId+'&eventId='+this.$route.params.parentId;
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>
