<template>
  <v-select
    :label='$t("model.name.school")'
    key="schoolId"
    class="white"
    :items="data"
    item-text="name"
    item-value="id"
    v-model="id"
    :loading="api.isLoading"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError", "value"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
    computed: mapState({
    school: (state) => state.school.data,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
        this.data = this.$_.filter(resp, (o) => {
              return o.id != this.school.id;
            });
      this.item = this.data[0];
      this.id =  this.data[0].id;
    };
    //EOC
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.callbackSelect(val);
    },
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/" +
        this.$_getLocale() +
        "/data/school";
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
