<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
      <v-card-title>{{$t("action.assign_other_school")}}</v-card-title>
        <v-card-text>
            <template>
             <School
              ref="dropdown_school"
              :callbackSelect="selectSchoolId"
              :value="form['schoolId']"
              @input="form['schoolId'] = $event"
            />
          </template>
           <template>
             <Classroom
              ref="dropdown_classroom"
              :callbackSelect="selectClassroomId"
              :value="form['classroomId']"
              @input="form['classroomId'] = $event"
              :schoolId="schoolId"
            />
          </template>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$router.go(-1)"
        >
          {{$t("action.cancel")}}
        </v-btn>
        <v-btn
          color="primary"
          :loading="api.isLoading"
          @click="validate"
        >
          {{$t("action.confirm")}}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>


<script>
import School from "@/components/Bread/ClassroomOnEvent/OtherSchool/School";
import Classroom from "@/components/Bread/ClassroomOnEvent/OtherSchool/Classroom";
import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
   School,
   Classroom
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    schoolId:null,
    //BOC:[form]
    form: {
      schoolId: null,
      classroomId: null,
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectSchoolId(id) {
      this.form.schoolId = id;
this.schoolId =id
        var that = this
        setTimeout(()=>{
          that.$refs['dropdown_classroom'].fetch()
        },100)
    },
    selectClassroomId(id) {
      this.form.classroomId = id;
    },
   
  
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
            this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
   mounted() {
      this.$refs['dropdown_school'].fetch()
    },
};
</script>
